import 'flowbite';
import '../../shared/js/shared.js'

/**
 * Timeline
 */
document.addEventListener("DOMContentLoaded", () => {
    const targets = document.querySelectorAll('.lyb-timeline-icon');
    const viewportHeight = window.innerHeight;

    const timelineAnimations = () => {
        targets.forEach(target => {
            const rect = target.getBoundingClientRect();
            if (rect.top < viewportHeight / 2) {
                target.classList.add('past-50');
            } else {
                target.classList.remove('past-50');
                target.style.webkitAnimationPlayState = "running"
            }
        });
    };

    // Initial check in case elements are already past 50% on load
    timelineAnimations();

    // Attach the scroll event listener
    window.addEventListener('scroll', timelineAnimations);
})
